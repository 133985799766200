@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Peyda;
  font-display: swap;
  src: url(./assets/assets/font/Peyda/ttf/Peyda-Regular.ttf) format('truetype'),
  url(./assets/assets/font/Peyda/eot/PeydaWeb-Regular.eot) format('embedded-opentype'),
  url(./assets/assets/font/Peyda/woff/PeydaWeb-Regular.woff) format('woff'),
  url(./assets/assets/font/Peyda/woff2/PeydaWeb-Regular.woff2) format('woff2');
}
* {
    box-sizing: border-box;
    transition: all 0.3s;
    direction: rtl;
  }
  
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
  }
  
  #root {
    overflow: auto;
  }
  
  body {
    position: fixed;
    overflow: hidden;
    overscroll-behavior-y: none;
    font-family:Peyda, -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
    color: black;
    -webkit-font-smoothing: antialiased;
    background: #f0f0f0;
  }
  .section{
    height: 100vh;
    display: flex;
    direction: rtl;
  }
